<template>
  <div class="aggregation">
    <SlOverlay :show="isLoading" />

    <Transform
      ref="transform"
      :required-tab-keys="requiredTabs"
    >
      <template #header>
        <div class="aggregation__header">
          <div class="aggregation__header-title heading-4-md grey-80">
            {{ $t('Web.Integrations.Aggregation.QueriesTitle') }}
          </div>
          <div class="aggregation__header-actions">
            <SlButton
              variant="secondary"
              color="grey"
              @click="handleCancel"
            >
              {{ $t('Common.Cancel') }}
            </SlButton>
            <SlButton @click="handleConfirm">
              {{ $t('Web.Integrations.Actions.BtnConfirm') }}
            </SlButton>
          </div>
        </div>
      </template>
    </Transform>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import Transform from '@/components/Integrations/Modules/Transform/Import/Index.vue';
import { modal } from '@/mixins/modal';
import { routeNames } from '@/config/router/router.config';
import { requiredTabs } from '@/config/integrations/transform.config';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'Aggregation',
  components: {
    Transform
  },
  mixins: [modal],
  data() {
    return {
      requiredTabs,
      isLoading: false
    };
  },
  provide() {
    return {
      setIsLoading: this.setIsLoading
    };
  },
  computed: {
    ...mapState({
      isEdited: state => state.integrations.is_edited
    })
  },
  destroyed() {
    this.resetTransformState();
    this.setIsEdited(false);
  },
  beforeRouteLeave(to, from, next) {
    this.onRouteLeave(next);
  },
  methods: {
    ...mapActions({
      setIsEdited: 'integrations/setIsEdited',
      resetTransformState: 'integrations/transform/resetState'
    }),
    setIsLoading(value) {
      this.isLoading = value;
    },
    onRouteLeave(next) {
      if (this.isEdited) {
        return this.showModal(modalsId.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Integrations.Modals.TitleLeaveAggregation'),
          text: this.$t('Web.Integrations.Modals.TextLeave'),
          icon: 'style_warning_double',
          confirmText: this.$t('Web.Integrations.Modals.BtnLeave'),
          confirmCallback: () => {
            this.setIsEdited(false);

            next();
          }
        });
      }

      next();
    },
    handleCancel() {
      this.$router.push({
        name: routeNames.INTEGRATIONS
      });
    },
    async handleConfirm() {
      const result = await this.$refs.transform.finish();

      if (!result) {
        return;
      }

      await this.setIsEdited(false);

      this.$router.push({
        name: routeNames.INTEGRATIONS
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/utils/_variable.scss';
@import '@/style/utils/_color.scss';
@import '@/style/utils/_mixins.scss';

.aggregation {
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 12px;

    &-actions {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  ::v-deep .subpage-wrapper {
    .subpage-wrapper__main {
      height: calc(100% - 44px);
    }
  }
}
</style>
